import {
    createTheme,
    genPageTheme,
    lightTheme,
    shapes,
} from '@backstage/theme';

export const NandosTheme = createTheme({
    palette: {
        ...lightTheme.palette,
        primary: {
            main: '#212c42',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#343b58',
            contrastText: '#ffffff',
        },
        error: {
            main: '#111',
            contrastText: '#ffffff',
        },
        warning: {
            main: '#ff9800',
            contrastText: '#ffffff',
        },
        info: {
            main: '#2196f3',
            contrastText: '#ffffff',
        },
        success: {
            main: '#63a143',
            contrastText: '#ffffff',
        },
        background: {
            default: '#f5f5f5',
            paper: '#ffffff',
        },
        banner: {
            info: '#2196f3',
            error: '#f44336',
            text: '#fff',
            link: '#ff6f00',
        },
        errorBackground: '#f44336',
        warningBackground: '#ff9800',
        infoBackground: '#2196f3',
        navigation: {
            background: '#212c42',
            indicator: '#cd0129',
            color: '#ffffff',
            selectedColor: '#ccc',
            navItem: {
                hoverBackground: "#1a3770"
            },
            submenu: {
                background: '#15223b'
            }
        },
    },
    defaultPageTheme: 'home',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    /* below drives the header colors */
    pageTheme: {
        home: genPageTheme({ colors: ['#CE0A24', '#A51428'], shape: shapes.wave }),
        documentation: genPageTheme({ colors: ['#39A07D', '#39A07D'], shape: shapes.wave }),
        tool: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.round }),
        service: genPageTheme({
            colors: ['#FFFF00', '#FFFF00'],
            shape: shapes.wave,
            options: {
                fontColor: '#111'
            }
        }),
        website: genPageTheme({
            colors: ['#8c4351', '#343b58'],
            shape: shapes.wave,
        }),
        library: genPageTheme({
            colors: ['#8c4351', '#343b58'],
            shape: shapes.wave,
        }),
        other: genPageTheme({ colors: ['#1a3770', '#102143'], shape: shapes.wave }),
        app: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
        apis: genPageTheme({ colors: ['#1a3770', '#102143'], shape: shapes.wave }),

    },
}); 94