import React from 'react';
import MyCustomLogoFull from './logo/backstage_logo.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 42,
    
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();
  return <img src={MyCustomLogoFull} className={classes.svg} />;
};
export default LogoFull;
